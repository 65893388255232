import theme from 'app/theme';
import { css } from 'styled-components/macro';

export const FilterBaseStyle = css`
  //z-index: -1;
  display: flex;
  //height: 100%;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: calc(100vw - 183px);
  background: #ffffff;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.12);
  border-radius: 49px;
  padding: 0 16px;
  // TODO: find alternative cause this doesn't work with search results panel
  overflow: unset !important;
  position: relative;
  @media (max-width: 960px) {
    padding-right: 0;
  }
  @media (min-width: 902px) {
    width: 100%;
  }
  @media (max-width: 599px) {
    width: calc(100vw - 168px);
  }
`;

export const FilterDividerStyle = css`
  width: 0;
  height: 12px;
  margin-left: 16px;
`;

export const StatDividerStyle = css`
  height: 32px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
`;

export const FilterItemContainer = (
  active: boolean,
  hasFilters: boolean
) => css`
  display: flex;
  color: #2e4063;
  padding-left: 16px;
  align-items: center;
  border-radius: 49px;
  justify-content: center;
  ${active
    ? `
    color: ${theme.palette.primary.main};
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
    :first-child{
      box-shadow: 4px 1px 14px -2px rgba(0, 0, 0, 0.12);
    }
    
    :last-child{
      box-shadow: -4px 1px 14px -2px rgba(0, 0, 0, 0.12);
    }
  `
    : `
    &:hover {
      cursor: pointer;
      color: ${theme.palette.primary.main};
    }
  `}

  ${hasFilters
    ? `
    > div {
      &:after {
        top: 0px;
        right: -8px;
        width: 6px;
        height: 6px;
        content: '';
        position: absolute;
        border-radius: 50%;
        background: ${theme.palette.primary.main};
      }
    }
  `
    : ''}
`;

export const MainCircleStyle = (mainOpen: boolean) => css`
  position: relative;
  top: -44px;
  right: ${mainOpen ? -129 : -112}px;
  z-index: 100;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${theme.palette.primary.main};
`;

export const MoreCircleStyle = (mainOpen: boolean) => css`
  position: relative;
  top: -44px;
  right: ${mainOpen ? -237 : -220}px;
  z-index: 100;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${theme.palette.primary.main};
`;
