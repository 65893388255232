import * as React from 'react';

export function LogoGithub(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={31} height={31} fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M15.174.5c-8.438 0-15 6.563-15 15 0 9.844 8.906 14.063 10.312 14.063.938 0 .938-.47.938-.938v-2.344c-3.281.938-4.688-.937-5.156-2.344 0 0 0-.468-.938-1.406-.469-.469-2.344-1.406-.469-1.406C6.268 21.125 7.205 23 7.205 23c1.406 1.875 3.281 1.406 4.219.938 0-.938.937-1.875.937-1.875-3.75-.47-6.562-1.875-6.562-7.032 0-1.875.469-3.281 1.406-4.219 0 0-.937-1.874 0-4.218 0 0 2.344 0 4.219 1.875 1.406-.938 6.094-.938 7.5 0 1.875-1.875 4.219-1.875 4.219-1.875.937 3.281 0 4.218 0 4.218.937.938 1.406 2.344 1.406 4.22 0 5.155-3.281 6.562-6.563 7.03.47.47.938 1.407.938 2.813v3.75c0 .469 0 .938.937.938 1.407 0 10.313-4.22 10.313-14.063 0-8.438-6.563-15-15-15z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" transform="translate(.174 .5)" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
