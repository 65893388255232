import React, { FunctionComponent } from 'react';
import 'styled-components/macro';
import { Box, Grid, Typography } from '@material-ui/core';
import { TextButton } from '../../Buttons/TextButton';
import { FilledButton } from '../../Buttons/FilledButton';

export interface BottomControlsProps {
  handleApplyClick: Function;
  handleCancelClick: Function;
  disableApply?: boolean;
  displaySelected?: boolean;
  selectedOptions: any;
}

export const BottomControls: FunctionComponent<BottomControlsProps> = (
  props
) => (
  <Grid container alignItems="center" justifyContent="space-between">
    {/* ------------------------------------ */}
    {/* selected indicator */}
    <Grid item sm={4} md={4} lg={6}>
      {props.displaySelected && (
        <Typography
          css={`
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 1;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba(61, 61, 61, 0.6);
            transform: translateX(1px);
          `}
        >
          {props.selectedOptions.length} selected
        </Typography>
      )}
    </Grid>

    {/* ------------------------------------ */}
    {/* buttons */}
    <Grid
      item
      sm={8}
      md={8}
      lg={6}
      css={`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      `}
    >
      <TextButton label="Cancel" onClick={() => props.handleCancelClick()} />
      <Box width="24px" />
      <FilledButton
        aria-label="Apply filter"
        label="Apply"
        onClick={() => props.handleApplyClick()}
        disabled={props.disableApply}
      />
    </Grid>
  </Grid>
);
