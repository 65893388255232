const webshopBasePath = '/';
const basePath = '/app/';

type PathItemType = {
  name: string;
  path: string;
};

interface PathProps {
  general: PathItemType;
  export: PathItemType;
}

export const Path = {
  basePath,
  webshopBasePath,
  webshop: {
    home: `${webshopBasePath}`,
    about: `${webshopBasePath}about`,
    support: `${webshopBasePath}support`,
    knowledgeHub: `${webshopBasePath}support/knowledgeHub`,
    services: `${webshopBasePath}services`,
    plans: `${webshopBasePath}plans`,
    onboarding: `${webshopBasePath}onboarding/:page?`,
    payment: `${webshopBasePath}payment/:page?`,
  },
  general: {
    analytics: `${basePath}analytics`,
    privacy: `${basePath}privacy`,
    contact: `${basePath}contact`,
    faq: `${basePath}faq`,
    about: `${basePath}about`,
    notFound: `${basePath}notFound`,
  },
  home: basePath,
  explore: `${basePath}explore`,
  search: `${basePath}search-results`,
  detail: {
    activity: `${basePath}activity/:param`,
    publisher: `${basePath}publisher/:param`,
    donor: `${basePath}donor/:param`,
    country: `${basePath}country/:param`,
    organisation: `${basePath}organisation/:param`,
  },
  bookmarks: `${basePath}bookmarks`,
  userManagement: {
    userSettingsRoute: `${basePath}user-settings/:id`,
    userSettingsRouteWithoutId: `${basePath}user-settings`,
    userSetting: `${basePath}user-settings/`,
    userOverview: `${basePath}users`,
    teamDetail: `${basePath}team`,
    teamOverview: `${basePath}teams`,
  },
  dataManagement: {
    root: `${basePath}data-manager`,
    validationReport: `${basePath}validation-report/:orgRef/:uuid/:filename`,
    activities: {
      list: `${basePath}data-manager/activities`,
      edit: `${basePath}data-manager/activities/:id/edit`,
    },
    bulkManagement: {
      root: `${basePath}data-manager/bulk-management`,
      upload: `${basePath}data-manager/bulk-management/upload`,
      convert: `${basePath}data-manager/bulk-management/convert`,
      download: `${basePath}data-manager/bulk-management/download`,
    },
    publishedFiles: {
      root: `${basePath}data-manager/published-files`,
      upload: `${basePath}data-manager/published-files/upload`,
    },
    documents: {
      root: `${basePath}data-manager/documents`,
    },
    published: `${basePath}data-manager/published-files`,
    profile: {
      publishSettings: `${basePath}data-manager/profile-settings`,
      orgInfo: `${basePath}data-manager/profile-settings/orginfo`,
      activityList: `${basePath}data-manager/profile-settings/activity`,
      defaultValues: `${basePath}data-manager/profile-settings/default`,
    },
    registrySignup: 'https://www.iatiregistry.org/publisher/new',
    registryApiKeyKnowledgeHub: `${webshopBasePath}support/knowledgeHub`,
  },
  other: {
    iatiStandardSchema: `${webshopBasePath}support/knowledgeHub`,
  },
  debug: {
    components: `${basePath}components`,
  },
};
