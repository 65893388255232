import React from 'react';
import { css } from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import findIndex from 'lodash/findIndex';
import { MainFiltersCard, MainFiltersCardProps } from './common/MainFilterCard';
import { FilterProps } from '../index';
import { FilterOptionItem, OptionsModel } from './common/FilterOptionItem';

export const AcivityStatusFilterContainerStyle = css`
  padding: 30px 0px 20px 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  > div {
    max-width: 33%;
    flex-basis: 33%;
  }
`;

export const AcivityStatusFilter = (props: FilterProps) => (
  <MainFiltersCard title="Activity Status" {...props}>
    <FilterOptions {...props} key="AcivityStatusFilter-container" />
  </MainFiltersCard>
);

const FilterOptions = (props: MainFiltersCardProps) => {
  return (
    <Grid container item spacing={0}>
      <div css={AcivityStatusFilterContainerStyle}>
        {props.shownOptions.map((item: OptionsModel) => (
          <FilterOptionItem
            {...props}
            key={`${item.code}-${item.name}`}
            value={item.code}
            label={item.name}
            selected={
              findIndex(
                props.selectedOptions,
                (option: string) => option === item.code
              ) > -1
            }
          />
        ))}
      </div>
    </Grid>
  );
};
