import React from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { Checkbox, Grid, FormControlLabel } from '@material-ui/core';
import { FilterSelect } from './common/FilterSelect';
import { MoreFiltersCard } from './common/MoreFilterCard';
import { Label } from './common/FilterSelectStyles';
import { FilterProps } from '../index';

interface DefaultFilterProps extends FilterProps {
  filterOptionsDataLoading: any;
  loadFilterOptions: (arg0: string, arg1: boolean) => void;
}

export const DefaultFilter = (props: DefaultFilterProps) => {
  React.useEffect(() => props.loadFilterOptions('defaulttiedstatus', true), []);

  function onChange(actionMeta: any, type: string) {
    const options = { ...props.selectedOptions };
    if (actionMeta.action === 'select-option') {
      options[type] = [...options[type], actionMeta.option.value];
    }
    if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.removedValue.value
      );
    }
    if (actionMeta.action === 'deselect-option') {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.option.value
      );
    }
    if (actionMeta.action === 'clear') {
      options[type] = [];
    }
    props.setSelectedOptions(options);
  }

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const options = { ...props.selectedOptions };
    if (checked) {
      options.defaulttiedstatus = [
        ...options.defaulttiedstatus,
        event.target.value,
      ];
    } else {
      options.defaulttiedstatus = filter(
        options.defaulttiedstatus,
        (v: any) => v !== event.target.value
      );
    }
    props.setSelectedOptions(options);
  }

  return (
    <MoreFiltersCard
      header="Default filter"
      selectedOptions={props.selectedOptions}
    >
      {/* -------------------------------------- */}
      {/* default filter */}
      {/* -------------------------------------- */}
      {/* default aid type */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Default aid type</Label>
        <FilterSelect
          options={props.options.defaultaidtype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('defaultaidtype', true);
          }}
          defaultValue={filter(props.options.defaultaidtype, (item: any) =>
            find(
              props.selectedFilters.defaultaidtype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'defaultaidtype');
          }}
          isLoading={props.filterOptionsDataLoading.defaultaidtype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* default aid type category */}
      {/* <Grid item xs={12} md={12} lg={12}>
        <Label> Default aid type category</Label>
        <FilterSelect
          options={props.options.defaultaidtypecategory.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('defaultaidtypecategory', true);
          }}
          defaultValue={filter(
            props.options.defaultaidtypecategory,
            (item: any) =>
              find(
                props.selectedFilters.defaultaidtypecategory,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'defaultaidtypecategory');
          }}
          isLoading={props.filterOptionsDataLoading.defaultaidtypecategory}
        />
      </Grid> */}
      {/* -------------------------------------- */}
      {/* default aid type vocabulary */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Default aid type vocabulary</Label>
        <FilterSelect
          options={props.options.defaultaidtypevocabulary.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('defaultaidtypevocabulary', true);
          }}
          defaultValue={filter(
            props.options.defaultaidtypevocabulary,
            (item: any) =>
              find(
                props.selectedFilters.defaultaidtypevocabulary,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'defaultaidtypevocabulary');
          }}
          isLoading={props.filterOptionsDataLoading.defaultaidtypevocabulary}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* currency */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Currency</Label>
        <FilterSelect
          options={props.options.currency.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('currency', true);
          }}
          defaultValue={filter(props.options.currency, (item: any) =>
            find(props.selectedFilters.currency, (f: any) => f === item.code)
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'currency');
          }}
          isLoading={props.filterOptionsDataLoading.currency}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* default finance types */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Default finance types</Label>
        <FilterSelect
          options={props.options.defaultfinancetype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('defaultfinancetype', true);
          }}
          defaultValue={filter(props.options.defaultfinancetype, (item: any) =>
            find(
              props.selectedFilters.defaultfinancetype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'defaultfinancetype');
          }}
          isLoading={props.filterOptionsDataLoading.defaultfinancetype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* default flow type */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Default flow type</Label>
        <FilterSelect
          options={props.options.defaultflowtype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('defaultflowtype', true);
          }}
          defaultValue={filter(props.options.defaultflowtype, (item: any) =>
            find(
              props.selectedFilters.defaultflowtype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'defaultflowtype');
          }}
          isLoading={props.filterOptionsDataLoading.defaultflowtype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* default language */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Default language</Label>
        <FilterSelect
          options={props.options.language.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('language', true);
          }}
          defaultValue={filter(props.options.language, (item: any) =>
            find(props.selectedFilters.language, (f: any) => f === item.code)
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'language');
          }}
          isLoading={props.filterOptionsDataLoading.language}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* default tied status */}

      {/* todo: Need to fix these checkboxes inside an component */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Default tied status</Label>
        <Grid item>
          {props.options.defaulttiedstatus.map((item: any) => (
            <FormControlLabel
              key={item.name}
              css={`
                margin-left: 0;
              `}
              control={
                <Checkbox
                  color="default"
                  checked={find(
                    props.selectedOptions.defaulttiedstatus,
                    (f: any) => f === item.code
                  )}
                  value={item.code}
                  onChange={handleCheckboxChange}
                />
              }
              label={item.name}
            />
          ))}
        </Grid>
      </Grid>
    </MoreFiltersCard>
  );
};
