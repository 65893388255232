import React from 'react';
import { Path } from 'app/const/Path';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useWindowScroll } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoAida } from 'app/assets/LogoAida';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { NavLink, useLocation } from 'react-router-dom';
import {
  AppBarPageModel,
  pages,
} from 'app/modules/web-shop/components/appbar/data';
import {
  appbarcss,
  logolinkcss,
  appbarlinkcss,
  appbartoolbarcss,
  linksContainerCss,
} from 'app/modules/web-shop/components/appbar/style';

export function Appbar() {
  const location = useLocation();
  const { y } = useWindowScroll();
  const { user, isAuthenticated } = useAuth0();

  React.useEffect(() => {
    const hubspotChatbot = document.getElementById(
      'hubspot-messages-iframe-container'
    );
    if (hubspotChatbot) {
      hubspotChatbot.style.visibility = 'visible';
    }
  }, []);

  return (
    <Box
      component="nav"
      css={appbarcss}
      style={{
        backgroundColor: y > 78 ? '#def2f0' : 'transparent',
        display:
          location.pathname.indexOf(
            Path.webshop.onboarding.replace('/:page?', '')
          ) > -1 ||
          location.pathname.indexOf(
            Path.webshop.payment.replace('/:page?', '')
          ) > -1
            ? 'none'
            : 'block',
      }}
    >
      <Container maxWidth="lg" css={appbartoolbarcss}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xl={4} lg={6} sm={2}>
            <NavLink to={Path.webshop.home} css={logolinkcss}>
              <IconButton edge="start" color="inherit" aria-label="Go to home">
                <LogoAida />
              </IconButton>
              <div>AIDA</div>
            </NavLink>
          </Grid>
          <Grid item xl={8} lg={6} sm={10} css={linksContainerCss}>
            {pages.map((page: AppBarPageModel) => (
              <NavLink
                key={page.title}
                to={page.path}
                css={appbarlinkcss(location.pathname === page.path)}
              >
                {page.title}
              </NavLink>
            ))}
            <NavLink
              to={
                isAuthenticated && user
                  ? Path.home
                  : Path.webshop.onboarding.replace(':page?', '')
              }
            >
              <button type="button">My AIDA</button>
            </NavLink>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
