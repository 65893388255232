/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { Path } from 'app/const/Path';
import { css } from 'styled-components/macro';
import { useAuth0 } from '@auth0/auth0-react';
import { formatLocale } from 'app/utils/formatLocale';
import { PricingPlanModel } from 'app/interfaces/User';
import { createTheme } from '@material-ui/core/styles';
import { FilledButton } from 'app/components/Buttons/FilledButton';
import { FileUploadPopover } from 'app/components/FileUploadPopver';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { LinkCell } from 'app/components/Charts/table/common/cells/LinkCell';
import { BookmarkButton } from 'app/components/Filter/common/BookmarkButton';
import { ExpandableRows } from 'app/components/Charts/table/common/rows/ExpandableRows';
import { MultiValuesCell } from 'app/components/Charts/table/common/cells/MultiValuesCell';
import {
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline,
  DeleteOutlined,
  SendOutlined,
  DeleteOutline,
  Sort,
} from '@material-ui/icons';
import {
  MUIDataTableOptions,
  MUIDataTableColumnDef,
  MUIDataTableIsRowCheck,
  DisplayData,
  MUIDataTableMeta,
  MUIDataTableExpandButton,
} from 'mui-datatables';

type RenderCustomComponent<P> = (props: P) => React.ReactNode;

export interface DataTableProps {
  options: MUIDataTableOptions;
  title: string | React.ReactNode;
  columns: MUIDataTableColumnDef[];
  data: TableData;
  components?: {
    ExpandButton:
      | RenderCustomComponent<MUIDataTableExpandButton>
      | React.ReactNode;
  };
}

export type TableData = Array<object | number[] | string[]>;

export const tableTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        width: '100%',
      },
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        display: 'flex !important',
        '@media (max-width:600px)': {
          justifyContent: 'space-between',
          '& > div': {
            flex: '0 0 auto',
          },
        },
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 600,
        color: '#2E4063',
        fontFamily: 'Inter',
        fontSize: '20px',
      },
      body2: {
        fontSize: '12px',
        fontFamily: 'Inter',
        color: '#b6b6b6 !important',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#2E4063',
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: '#fff',
        fontFamily: 'Inter',
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Inter !important',
      },
      head: {
        padding: '10px 16px',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '1rem',
        borderTop: '2px solid #ebebeb',
        borderBottom: '2px solid #ebebeb',
        whiteSpace: 'nowrap',
        '&:first-of-type': {
          paddingLeft: '0px !important',
        },
      },
      body: {
        fontFamily: 'Inter',
        color: '#2E4063',
        fontSize: '14px',
        '&:first-of-type': {
          paddingLeft: '0px !important',
        },
      },
      footer: {
        borderBottomStyle: 'none',
        '@media (max-width:600px)': {
          padding: '0px !important',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Inter !important',
      },
    },
    MuiInputBase: {
      input: {
        color: '#b6b6b6',
      },
    },
    MuiTablePagination: {
      selectIcon: {
        fill: '#b6b6b6',
      },
    },
    MuiPopover: {
      paper: {
        width: 'fit-content',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        '&:last-of-type': {
          width: '150px',
        },
      },
      data: {
        color: 'rgba(61, 61, 61, 0.6)',
        fontWeight: 'bold',
      },
      sortActive: {
        color: 'rgba(61, 61, 61, 0.6)',
        fontWeight: 'bold',
      },
      sortAction: {
        alignItems: 'center',
      },
      contentWrapper: {
        display: 'inline-flex',
      },
    },
    MUIDataTableToolbar: {
      actions: {
        display: 'flex',
        flexDirection: 'row',
        flex: 'initial',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
    },
    MuiDialog: {
      paper: {
        maxWidth: '496px !important',
        borderRadius: '16px',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: '8px !important',
        marginTop: '0px !important',
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the button.
        visibility: 'hidden',
      },
    },
  },
});

export const ButtonStyle = {
  buttonPrimary: css`
    margin-bottom: 16px;
    margin-right: 16px;
    color: white;
    background-color: #2e4063;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 20px;
    padding: 6px 24px;
    text-transform: none;
    &:hover {
      opacity: 0.4;
      background-color: #2e4063;
    }
  `,
};

const DATE_MISSING = 'Date missing';
const ACTIVITY_TITLE_MOCK =
  'Promoting Opportunities for Women s Empowerment and Rights 1';

// Activities

export const ActivitiesDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'Planned start date',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  {
    name: 'Planned end date',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  { name: 'Status' },
  {
    name: 'Activity title',
    options: {
      customBodyRender: (value) => {
        const link = Path.detail.activity.replace(
          ':param',
          encodeURIComponent(value[0])
        );
        return <LinkCell link={link} value={value[1]} />;
      },
    },
  },
  {
    name: 'Country(s)',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const updValue = typeof value === 'object' ? value : [];
        return <MultiValuesCell value={updValue} />;
      },
    },
  },
  {
    name: '',
    options: {
      customHeadLabelRender: () => '',
      customBodyRender: (value, tableMeta) => {
        return (
          <BookmarkButton
            overridePath={tableMeta.rowData[3][0]}
            overrideName={tableMeta.rowData[3][1]}
          />
        );
      },
    },
  },
];

export const ActivitiesDataTableMockData: Array<object | number[] | string[]> =
  [
    [
      '1 Jan 2019',
      '2 Jan 2019',
      'Implementation',
      ['1001', ACTIVITY_TITLE_MOCK],
      ['Afghanistan', 'China', 'Kenya'],
      100,
    ],
    [
      '2 Jan 2019',
      '3 Jan 2019',
      'Implementation',
      ['1002', 'Promoting Opportunities for Women s Empowerment and Rights 2'],
      ['Afghanistan', 'China', 'Kenya'],
      100,
    ],
    [
      '3 Jan 2019',
      '4 Jan 2019',
      'Implementation',
      ['1003', 'Promoting Opportunities for Women s Empowerment and Rights 3'],
      ['Afghanistan', 'China', 'Kenya'],
      100,
    ],
  ];

export const ActivitiesDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  fixedHeader: false,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Countries

export const CountriesDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'Code',
  },
  {
    name: 'Country',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const link = `${Path.detail.country.replace(
          ':param',
          encodeURIComponent(value[1])
        )}/projects`;
        return <LinkCell link={link} value={value[1]} />;
      },
    },
  },
  {
    name: 'Outgoing Commitment',
    options: {
      customBodyRender: (value) => (
        <span>{new Intl.NumberFormat('nl-NL').format(value)}</span>
      ),
    },
  },
];

export const CountriesDataTableMockData: Array<object | number[] | string[]> = [
  ['AF', ['AF', 'Afghanistan'], 100],
  ['CN', ['CN', 'China'], 100],
  ['KE', ['KE', 'Kenya'], 100],
];

export const CountriesDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Organisations

export const OrganisationsDataTableColumns: MUIDataTableColumnDef[] = [
  { name: 'IATI ref' },
  {
    name: 'Organisation',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const label = value[1] === '' ? value[0] : value[1];
        const link = `${Path.detail.organisation.replace(
          ':param',
          encodeURIComponent(value[0])
        )}/projects`;
        return <LinkCell link={link} value={label} />;
      },
    },
  },
  {
    name: 'Budget',
    options: {
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const OrganisationsDataTableMockData: Array<
  object | number[] | string[]
> = [
  [
    'XM-DAC-6-4',
    ['XM-DAC-6-4', 'Italian Agency for Development Cooperation'],
    100,
  ],
  [
    'XM-DAC-701-8',
    ['XM-DAC-701-8', 'Japan International Cooperation Agency (JICA)'],
    100,
  ],
  [
    'XM-DAC-41114',
    ['XM-DAC-41114', 'United Nations Development Programme (UNDP)'],
    100,
  ],
];

export const OrganisationsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Documents

export const DocumentsColumns: MUIDataTableColumnDef[] = [
  {
    name: 'sn',
    label: 'S.N.',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || 'S.N. missing'}</>;
      },
    },
  },
  {
    name: 'fileName',
    label: 'File Name',
    options: {
      customBodyRender: (value) => {
        return <>{value || 'File Name missing'}</>;
      },
    },
  },
  {
    name: 'publishedDate',
    label: 'Published Date', // TODO: 'Publication Date' instead of 'Published Date'?
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  {
    name: 'lastUpdated',
    label: 'Last Updated', // TODO: Just 'Updated' instead of 'Last Updated'?
    options: {
      setCellHeaderProps: () => ({
        style: {
          textAlign: 'left',
          flexDirection: 'column',
        },
      }),
      setCellProps: () => ({
        style: {
          textAlign: 'left',
          minWidth: '120px',
          maxWidth: '150px',
          flexDirection: 'column',
        },
      }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
];

export const DocumentsTableMockData: Array<object | number[] | string[]> = [
  {
    sn: '1',
    fileName: 'DOCU-A.doc',
    publishedDate: '12/02/2021',
    lastUpdated: '20/03/2021',
  },
];

interface DocumentsOptionsProps {
  handleAddClick: any;
  handleDeleteClick: any;
}

export function DocumentsOptions(
  props: DocumentsOptionsProps
): MUIDataTableOptions {
  const { handleAddClick, handleDeleteClick } = props;
  return {
    print: true,
    elevation: 0,
    search: true,
    filter: false,
    download: true,
    rowHover: true,
    serverSide: true,
    pagination: true,
    viewColumns: false,
    responsive: 'standard',
    sort: true,
    customToolbar: () => {
      return (
        <>
          <div
            style={{
              order: -1,
              marginTop: '16px',
            }}
          >
            <FileUploadPopover title="Upload Document Files">
              <FilledButton
                aria-label="upload"
                label="Upload Document Files"
                onClick={() => handleAddClick()}
              />
            </FileUploadPopover>
          </div>
          <div
            style={{
              height: '24px',
              width: '100%',
              flexBasis: '100%',
              order: -1,
            }}
          />
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              style={{ order: -1 }}
              onClick={() => handleDeleteClick()}
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };
}

// Donors

export const DonorsDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'IATI ref',
  },
  {
    name: 'Donor',
    options: {
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const label = value[1] === '' ? value[0] : value[1];
        const link = `${Path.detail.donor.replace(
          ':param',
          encodeURIComponent(value[0])
        )}/projects`;
        return <LinkCell link={link} value={label} />;
      },
    },
  },
  {
    name: 'Budget',
    options: {
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const DonorsDataTableMockData: Array<object | number[] | string[]> =
  OrganisationsDataTableMockData;

export const DonorsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Publishers

export const PublishersDataTableColumns: MUIDataTableColumnDef[] = [
  { name: 'IATI ref' },
  {
    name: 'Publisher',
    options: {
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const label = value[1] === '' ? value[0] : value[1];
        const link = `${Path.detail.publisher.replace(
          ':param',
          encodeURIComponent(value[0])
        )}/projects`;
        return <LinkCell link={link} value={label} />;
      },
    },
  },
  {
    name: 'Outgoing Commitment',
    options: {
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const PublishersDataTableMockData: Array<object | number[] | string[]> =
  OrganisationsDataTableMockData;

export const PublishersDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Sectors

export const SectorsDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'code',
    label: 'Code',
    options: { sort: false },
  },
  {
    name: 'title',
    label: 'Category / DAC3 / DAC5',
    options: {
      sort: false,
    },
  },
  {
    name: 'size',
    label: 'Budget',
    options: {
      sort: false,
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
  {
    name: 'sum',
    label: 'Sum of budgets',
    options: {
      sort: false,
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const SectorsDataTableMockData: Array<object | number[] | string[]> = [
  {
    code: '1',
    title: {
      code: '1',
      name: 'Sector 1',
    },
    size: 100,
    children: [
      {
        code: '1A',
        title: 'Sector 1 | A',
        size: 50,
        children: [
          {
            code: '1AI',
            title: 'Sector 1 | A | I',
            size: 25,
          },
          {
            code: '1AIV',
            title: 'Sector 1 | A | IV',
            size: 25,
          },
        ],
      },
      {
        code: '1B',
        title: 'Sector 1 | B',
        size: 50,
      },
    ],
  },
  {
    code: '2',
    title: {
      code: '2',
      name: 'Sector 2',
    },
    size: 200,
    children: [
      {
        code: '2A',
        title: 'Sector 2 | A',
        size: 100,
        children: [
          {
            code: '2AI',
            title: 'Sector 2 | A | I',
            size: 50,
          },
          {
            code: '2AIV',
            title: 'Sector 2 | A | IV',
            size: 50,
          },
        ],
      },
      {
        code: '2B',
        title: 'Sector 2 | B',
        size: 100,
      },
    ],
  },
];

export const SectorsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: true,
  serverSide: true,
  pagination: true,
  viewColumns: true,
  expandableRows: true,
  responsive: 'standard',
  selectableRows: 'none',
  expandableRowsHeader: false,
  selectableRowsHeader: false,
  expandableRowsOnClick: true,
  renderExpandableRow: (
    rowData: string[],
    rowMeta: { dataIndex: number; rowIndex: number }
  ) => {
    // @ts-ignore
    const childData = SectorsDataTableMockData[rowMeta.rowIndex].children;
    if (childData) {
      return <ExpandableRows data={childData} level={1} />;
    }
  },
  isRowExpandable: (dataIndex: number, expandedRows?: MUIDataTableIsRowCheck) =>
    // @ts-ignore
    SectorsDataTableMockData[dataIndex].children,
};

export const ManagableActivitiesColumns: MUIDataTableColumnDef[] = [
  {
    name: 'startDate',
    label: 'Start date',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  {
    name: 'endDate',
    label: 'End date',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'activityTitle',
    label: 'Activity Title',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const link = Path.detail.activity.replace(
          ':param',
          encodeURIComponent(value[0])
        );
        return <LinkCell link={link} value={value[1]} />;
      },
    },
  },
  {
    name: 'countries',
    label: 'Country(s)',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const updValue = typeof value === 'object' ? value : [];
        return <MultiValuesCell value={updValue} />;
      },
    },
  },
  {
    name: 'result',
    label: 'Result',
  },
];

export const ManagableActivitiesMockData: Array<object | number[] | string[]> =
  [
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Draft',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Complete',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Invalid',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Draft',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Draft',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Draft',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
    {
      startDate: '29 Jan 2018',
      endDate: '',
      status: 'Draft',
      activityTitle: ['1001', ACTIVITY_TITLE_MOCK],

      countries: ['Afghanistan', 'China', 'Kenya'],
      result: 1,
    },
  ];

interface ManagableActivitiesOptionsProps {
  handleAddClick: () => void;
  handleDeleteClick: () => void;
  handlePublishClick: () => void;
  handleSortClick: () => void;
}

export const ManageableTableOptions = ManagableActivitiesOptions({
  handleSortClick: () => console.log('sort clicked'),
  handlePublishClick: () => console.log('publish clicked'),
  handleDeleteClick: () => console.log('delete clicked'),
  handleAddClick: () => console.log('add clicked'),
});

export function ManagableActivitiesOptions(
  props: ManagableActivitiesOptionsProps
): MUIDataTableOptions {
  const {
    handleAddClick,
    handleDeleteClick,
    handlePublishClick,
    handleSortClick,
  } = props;
  return {
    print: true,
    elevation: 0,
    search: true,
    filter: false,
    download: true,
    rowHover: true,
    serverSide: true,
    pagination: true,
    viewColumns: true,
    responsive: 'standard',
    sort: true,
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Add Activity">
            <IconButton
              aria-label="add"
              style={{ order: -1 }}
              onClick={() => handleAddClick()}
            >
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              style={{ order: -1 }}
              onClick={() => handleDeleteClick()}
            >
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Publish">
            <IconButton
              aria-label="send"
              style={{ order: -1 }}
              onClick={() => handlePublishClick()}
            >
              <SendOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sort">
            <IconButton
              aria-label="sort"
              style={{ order: 4 }}
              onClick={() => handleSortClick()}
            >
              <Sort />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };
}

export const ConversionColumns: MUIDataTableColumnDef[] = [
  {
    name: 'Activity reference',
  },
  {
    name: 'Message',
  },
  {
    name: 'Context',
  },
];

export const ConversionOptions: MUIDataTableOptions = {
  elevation: 0,
  search: false,
  print: false,
  viewColumns: false,
  sort: false,
  download: false,
  filter: false,
  pagination: false,
  selectableRows: 'none',
  responsive: 'standard',
  // expandableRows: true,
  expandableRowsHeader: false,
  // expandableRowsOnClick: true,
  // renderExpandableRow: (rowData: any, rowMeta: any) => {
  //   const colSpan = rowData.length + 1;
  //   return (
  //     <TableRow>
  //       <TableCell colSpan={colSpan} css="padding-left: 64px !important;">
  //         Invalid value(s) for
  //       </TableCell>
  //     </TableRow>
  //   );
  // },
};

export const ConversionMockData = [
  ['exampleXML.xml', 6],
  ['exampleXML (1).xml', 4],
  ['exampleXML (2).xml', 2],
];

// Published files

export const PublishedFilesColumns: MUIDataTableColumnDef[] = [
  {
    name: 'sn',
    label: 'S.N.',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || 'S.N. missing'}</>;
      },
    },
  },
  {
    name: 'publishedDate',
    label: 'Published Date',
    options: {
      customBodyRender: (value) => {
        return <>{value || 'Published Date missing'}</>;
      },
    },
  },
  {
    name: 'fileName',
    label: 'File Name',
    options: {
      customBodyRender: (value) => {
        return <>{value || 'File Name missing'}</>;
      },
    },
  },
  {
    name: 'registered',
    label: 'Registered in IATI Registry',
    options: {
      customBodyRender: (value) => {
        return <>{value || 'IATI registration status missing'}</>;
      },
    },
  },
  {
    name: 'download',
    label: 'Download',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const link = `#`;
        return <LinkCell link={link} value={value[1]} />;
      },
    },
  },
];

export const PublishedOrganisationFilesTableMock: Array<
  object | number[] | string[]
> = [
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
];

export const PublishedActivitiesFilesTableMock: Array<
  object | number[] | string[]
> = [
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
  {
    sn: '1',
    publishedDate: '12 Feb 2021',
    fileName: 'ORG-A.doc',
    registered: 'Yes',
    download: ['1001', 'CSV'],
  },
];

interface PublishedFilesOptionsProps {
  handleAddClick: () => void;
}

export function PublishedOrganisationFilesOptions(
  props: PublishedFilesOptionsProps
): MUIDataTableOptions {
  const { handleAddClick } = props;
  return {
    print: false,
    elevation: 0,
    search: false,
    filter: false,
    download: false,
    rowHover: true,
    serverSide: true,
    pagination: true,
    viewColumns: false,
    responsive: 'standard',
    sort: true,
    customToolbar: () => {
      return (
        <>
          <FilledButton
            label="Upload Organisation Files"
            onClick={() => handleAddClick()}
          />
        </>
      );
    },
  };
}

export function PublishedActivitiesFilesOptions(
  props: PublishedFilesOptionsProps
): MUIDataTableOptions {
  const { handleAddClick } = props;
  return {
    print: false,
    elevation: 0,
    search: false,
    filter: false,
    download: false,
    rowHover: true,
    serverSide: true,
    pagination: true,
    viewColumns: false,
    responsive: 'standard',
    sort: true,
    customToolbar: () => {
      return (
        <>
          <FilledButton
            label="Upload Activities Files"
            onClick={() => handleAddClick()}
          />
        </>
      );
    },
  };
}

// Org members

const MemberRoleDropdownComp = (props: {
  userId: string;
  value: 'Admin' | 'Member';
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const changeUserRoleAction = useStoreActions(
    (actions) => actions.changeUserRole.authPostFetch
  );
  const roles = useStoreState((state) => get(state.roles, 'data.items', []));
  const isAdmin = useStoreState(
    (reduxState) =>
      get(reduxState.user.data, 'user.app_metadata.role', '') === 'Admin'
  );

  return (
    <div
      css={`
        width: 100px;
      `}
    >
      <button
        css={`
          width: 100%;
          padding: 7px 0;
          text-align: start;
          border-style: none;
          position: relative;
          background-color: transparent;
          cursor: ${isAdmin ? 'pointer' : 'default'};

          > svg {
            top: 3px;
            right: 3px;
            position: absolute;

            > path {
              fill: #2e4063;
            }
          }
        `}
        type="button"
        onClick={isAdmin ? handleClick : undefined}
      >
        <Typography
          variant="h5"
          css={`
            color: #2e4063;
            font-size: 14px;
            font-weight: bold;
          `}
        >
          {props.value}
        </Typography>
        {isAdmin && open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div
            css={`
              width: 100px;
              padding: 7px 0;
              cursor: pointer;
              font-size: 14px;
              background: #fff;
              font-weight: bold;
              border-radius: 8px;
              text-align: center;
              box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
            `}
            onClick={() => {
              const newValue = props.value === 'Admin' ? 'Member' : 'Admin';
              const roleId = get(find(roles, { name: newValue }), '_id', '');
              if (roleId) {
                getAccessTokenSilently().then((token: string) => {
                  changeUserRoleAction({
                    values: {
                      token,
                      userId: props.userId,
                      roleId,
                      roleName: newValue,
                    },
                  });
                  setAnchorEl(null);
                });
              }
            }}
          >
            {props.value === 'Admin' ? 'Member' : 'Admin'}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const MemberRowDeleteComp = (props: {
  userId: string;
  handleRemoveUsers: (value: string[]) => void;
}) => {
  return (
    <IconButton
      aria-label="delete"
      id="delete-member-button"
      css={`
        visibility: hidden;
      `}
      onClick={() => {
        props.handleRemoveUsers([props.userId]);
      }}
    >
      <DeleteOutlined />
    </IconButton>
  );
};

export function OrgMembersColumns(props: {
  handleRemoveUsers: (value: string[]) => void;
}): MUIDataTableColumnDef[] {
  return [
    {
      name: 'user_id',
      options: {
        display: undefined,
      },
    },
    {
      name: 'email',
      options: {
        display: undefined,
      },
    },
    {
      name: 'picture',
      options: {
        display: undefined,
      },
    },
    {
      name: 'name',
      label: '',
      options: {
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '250px' },
        }),
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <div
              css={`
                gap: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              {rowData[2] ? (
                <img
                  alt="user"
                  width={48}
                  height={48}
                  src={rowData[2]}
                  css={`
                    border-radius: 50%;
                  `}
                />
              ) : (
                <div
                  css={`
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background: #a1aebd;
                  `}
                />
              )}
              <div
                css={`
                  gap: 5px;
                  display: flex;
                  flex-direction: column;

                  h5 {
                    font-size: 14px;

                    &:first-child {
                      font-weight: bold;
                    }
                  }
                `}
              >
                <div
                  css={`
                    gap: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  `}
                >
                  <Typography variant="h5">{rowData[3]}</Typography>
                  {!rowData[4] && (
                    <div
                      css={`
                        color: #a1aebd;
                        font-size: 12px;
                        padding: 3px 10px;
                        border-radius: 31px;
                        border: 1px solid #a1aebd;
                      `}
                    >
                      Pending
                    </div>
                  )}
                </div>
                <Typography variant="h5">{rowData[1]}</Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'email_verified',
      options: {
        display: undefined,
      },
    },
    {
      name: 'role',
      label: '',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <div
              css={`
                display: flex;
                flex-direction: row;
                justify-content: center;
              `}
            >
              <MemberRoleDropdownComp value={value} userId={rowData[0]} />
            </div>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <div
              css={`
                display: flex;
                flex-direction: row;
                justify-content: center;
              `}
            >
              <MemberRowDeleteComp
                userId={rowData[0]}
                handleRemoveUsers={props.handleRemoveUsers}
              />
            </div>
          );
        },
      },
    },
  ];
}

interface OrgMembersOptionsProps {
  data: any;
  handleAddClick: () => void;
  pricingPlan?: PricingPlanModel;
  handleSearchChange: (value: string) => void;
  handleRemoveUsers: (userIds: string[]) => void;
}

function OrgMembersToolbar(props: OrgMembersOptionsProps) {
  let errorText = '';
  if (!props.pricingPlan || props.pricingPlan.name === 'Free') {
    errorText =
      'Free plan only available for 1 user, if you have more than one user in your organisation please upgrade to Standard plan or Premium plan';
  } else if (
    props.pricingPlan &&
    props.pricingPlan.licenses === props.data.length
  ) {
    errorText =
      'You have reached the maximum number of users on your plan. Please upgrade your plan to add more users';
  }
  return (
    <Tooltip title={errorText.length > 0 ? errorText : 'Add user'}>
      <span>
        <IconButton
          disabled={errorText.length > 0}
          aria-label="Add user to organisation"
          style={{ order: -1, opacity: errorText.length > 0 ? 0.5 : 1 }}
          onClick={() => props.handleAddClick()}
        >
          <AddCircleOutline />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function OrgMembersOptions(
  props: OrgMembersOptionsProps
): MUIDataTableOptions {
  return {
    search: true,
    print: false,
    elevation: 0,
    filter: false,
    download: false,
    rowHover: true,
    serverSide: true,
    pagination: true,
    viewColumns: false,
    responsive: 'standard',
    sort: false,
    // onRowsDelete: (
    //   rowsDeleted: {
    //     lookup: { [dataIndex: number]: boolean };
    //     data: Array<{ index: number; dataIndex: number }>;
    //   },
    //   newTableData: any[]
    // ) => {
    //   const userIds: string[] = [];
    //   selectedRows.data.forEach((row) => {
    //     const user = props.data[row.dataIndex];
    //     if (user && user.user_id) {
    //       userIds.push(user.user_id);
    //     }
    //   });
    //   if (files.length > 0) {
    //     props.handleRemoveUsers(userIds);
    //   }
    // },
    customToolbar: () => <OrgMembersToolbar {...props} />,
    customToolbarSelect: (
      selectedRows: {
        data: Array<{ index: number; dataIndex: number }>;
        lookup: { [key: number]: boolean };
      },
      displayData: DisplayData,
      setSelectedRows: (rows: number[]) => void
    ) => {
      const userIds: string[] = [];
      selectedRows.data.forEach((row) => {
        const user = props.data[row.dataIndex];
        if (user && user.user_id) {
          userIds.push(user.user_id);
        }
      });
      return (
        <React.Fragment>
          <div>
            <Typography variant="h6">
              {selectedRows.data.length} member(s) selected
            </Typography>
          </div>
          <div>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  if (userIds.length > 0) {
                    props.handleRemoveUsers(userIds);
                    setSelectedRows([]);
                  }
                }}
              >
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </div>
        </React.Fragment>
      );
    },
    onSearchChange: (value: string | null) =>
      props.handleSearchChange(value || ''),
  };
}
