import React from 'react';
import get from 'lodash/get';
import { useDebounce } from 'react-use';
import { DataTable } from 'app/components/Charts/table';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import {
  PublishersDataTableColumns,
  PublishersDataTableOptions,
} from 'app/components/Charts/table/data';
import { useRecoilState } from 'recoil';
import { selectedFilterAtom } from 'app/state/recoil/atoms';
import { getAPIFormattedFilters } from 'app/utils/getAPIFormattedFilters';
import { MUIDataTableOptions } from 'mui-datatables';

interface PublishersTableModuleProps {
  tableOptions?: MUIDataTableOptions;
}
export function PublishersTableModule(props: PublishersTableModuleProps) {
  const [selectedFilters] = useRecoilState(selectedFilterAtom);
  const [tablePage, setTablePage] = React.useState(0);
  const [tableRows, setTableRows] = React.useState(10);
  const [tableSortBy, setTableSortBy] = React.useState<{
    name: string;
    direction: 'asc' | 'desc';
  }>({
    name: 'Outgoing Commitment',
    direction: 'desc',
  });
  const [search, setSearch] = React.useState<undefined | string>();
  const [debouncedSearch, setDebouncedSearch] =
    React.useState<undefined | string>();

  const action = useStoreActions((actions) => actions.publishers.fetch);
  const actionData = useStoreState((actions) => actions.publishers.data);

  const tableData = get(actionData, 'data', []);
  const tableCount = get(actionData, 'count', []);
  const tableOptions = props.tableOptions
    ? props.tableOptions
    : PublishersDataTableOptions;

  const searchTerm = useStoreState((state) => state.syncSearch.value);

  function callAction(page: number, rows: number, sort: string) {
    const filters = getAPIFormattedFilters(selectedFilters);
    action({
      values: {
        filters: searchTerm.length > 0 ? searchTerm : filters,
        page,
        rows,
        sort,
        search: debouncedSearch,
      },
    });
  }

  React.useEffect(() => {
    callAction(
      tablePage,
      tableRows,
      `${tableSortBy.name} ${tableSortBy.direction}`
    );
  }, [tablePage, tableRows, tableSortBy, selectedFilters, debouncedSearch]);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search]
  );

  return (
    <DataTable
      data={tableData}
      columns={PublishersDataTableColumns}
      options={{
        ...tableOptions,
        serverSide: true,
        sortOrder: tableSortBy,
        count: tableCount,
        onSearchChange: (searchText) =>
          searchText != null && setSearch(searchText),
        onSearchClose: () => {
          setSearch(undefined);
          setDebouncedSearch(undefined);
        },
        searchPlaceholder: 'Search publishers',
        onChangePage: setTablePage,
        onChangeRowsPerPage: setTableRows,
        onColumnSortChange: (
          changedColumn: string,
          direction: 'asc' | 'desc'
        ) =>
          setTableSortBy({
            name: changedColumn,
            direction,
          }),
      }}
      title={`AIDA has found ${new Intl.NumberFormat('nl-NL').format(
        tableCount
      )} publishers for you`}
    />
  );
}
