import React from 'react';
import get from 'lodash/get';
import { Path } from 'app/const/Path';
import { useRecoilState } from 'recoil';
import { Filter } from 'app/components/Filter';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import MUIAppBar from '@material-ui/core/AppBar';
import { BigLogoAida } from 'app/assets/LogoAida';
import { drawerAtom } from 'app/state/recoil/atoms';
import { useStoreState } from 'app/state/store/hooks';
import IconButton from '@material-ui/core/IconButton';
import { NavLink, useLocation } from 'react-router-dom';

interface AppBarProps {
  forceHideFilter?: boolean;
}

export function AppBar(props: AppBarProps) {
  const location = useLocation();
  const setDrawerState = useRecoilState(drawerAtom)[1];

  const isOnFreePlan = useStoreState(
    (state) =>
      get(state.user, 'data.user.app_metadata.pricingPlan.name', 'Free') ===
      'Free'
  );

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawerState(open);
    };

  const openSearch = location.pathname.indexOf('/search-results') > -1;

  React.useEffect(() => {
    setTimeout(() => {
      const hubspotChatbot = document.getElementById(
        'hubspot-messages-iframe-container'
      );
      if (hubspotChatbot && isOnFreePlan) {
        hubspotChatbot.style.visibility = 'hidden';
      }
    }, 2000);
  }, [isOnFreePlan]);

  return (
    <MUIAppBar
      position="relative"
      color="inherit"
      css={`
        top: 0;
        position: sticky;
        margin-bottom: 8px;
        box-shadow: initial;
        box-shadow: -960px 0px 0px 0px #f7f7f7, 960px 0px 0px 0px #f7f7f7;

        @media (max-width: 1440px) {
          box-shadow: -669px 0px 0px 0px #f7f7f7, 669px 0px 0px 0px #f7f7f7;
        }
        @media (max-width: 800px) {
          box-shadow: -300px 0px 0px 0px #f7f7f7, 300px 0px 0px 0px #f7f7f7;
        }
        @media (max-width: 600px) {
          box-shadow: -100px 0px 0px 0px #f7f7f7, 100px 0px 0px 0px #f7f7f7;
        }
      `}
    >
      <Toolbar
        disableGutters
        css={`
          gap: 15px;
          display: flex;
          min-height: 84px;
          justify-content: space-between;
        `}
      >
        <NavLink to={Path.home}>
          <IconButton edge="start" color="inherit" aria-label="Go to home">
            <BigLogoAida />
          </IconButton>
        </NavLink>
        <Filter openSearch={openSearch} forceHide={props.forceHideFilter} />
        <div
          css={`
            width: 71px;
            height: 44px;
            background: #fff;
            padding-left: 10px;
            border-radius: 25px;
            box-shadow: 0 1px 14px rgb(0 0 0 / 12%);
          `}
        >
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            data-cy="burger-menu-button"
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </MUIAppBar>
  );
}
