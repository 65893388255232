export const activityScope = [
  { value: 'post', label: 'A01: Pre- and post-project impact appraisal' },
  { value: 'objectives', label: 'A02: Objectives / Purpose of activity' },
  { value: 'intended', label: 'A03: Intended ultimate beneficiaries' },
  { value: 'conditions', label: 'A04: Conditions' },
  { value: 'budget', label: 'A05: Budget' },
];

export const documentCategory = [
  { value: 'post', label: 'A01: Pre- and post-project impact appraisal' },
  { value: 'objectives', label: 'A02: Objectives / Purpose of activity' },
  { value: 'intended', label: 'A03: Intended ultimate beneficiaries' },
  { value: 'conditions', label: 'A04: Conditions' },
  { value: 'budget', label: 'A05: Budget' },
];

export const hierarchy = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];
export const humanitarian = [
  { value: '1', label: 'True' },
  { value: '0', label: 'False' },
];

export const humanitarianScope = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

export const humanitarianVocabulary = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

export const iatiVersion = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

export const otherType = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

export const tag = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

export const secondaryReporter = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];
