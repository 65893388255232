import React from 'react';
import { ChangePhotoDialog } from 'app/components/Dialogs/ChangePhotoDialog';
import { ChangeEmailDialog } from 'app/components/Dialogs/ChangeEmailDialog';
import { ChangePasswordDialog } from 'app/components/Dialogs/ChangePasswordDialog';
import { PasswordSecurityDialog } from 'app/components/Dialogs/PasswordSecurityDialog';
import { ContinueExploringDialog } from 'app/components/Dialogs/ContinueExploringDialog';

export const AppDialogs = () => {
  return (
    <React.Fragment>
      {/* -------------------------------------- */}
      {/* user settings dialogs */}
      {/* <DeleteAccountDialog /> */}
      <ChangeEmailDialog />
      {/* <ChangePhonenumberDialog /> */}
      <ChangePhotoDialog />
      {/* <ChangeUsernameDialog /> */}
      {/* <ChangeBirthdayDialog /> */}
      <ChangePasswordDialog />
      <PasswordSecurityDialog />
      {/* -------------------------------------- */}
      {/* continue exploring AIDA dialog */}
      <ContinueExploringDialog />
    </React.Fragment>
  );
};
