import { APIModel } from 'app/state/api';
import { ApiCallModel } from 'app/state/api/interfaces';

export const getBookmarks: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_AUTH_API}/bookmarks`),
};

export const createBookmark: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_AUTH_API}/create-bookmark`),
};

export const deleteBookmark: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_AUTH_API}/delete-bookmark`),
};
