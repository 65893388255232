import React from 'react';
import { css } from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import findIndex from 'lodash/findIndex';
import { ProjectPalette } from 'app/theme';
import { formatCountryOptions } from './utils';
import { FilterOptionItem } from './FilterOptionItem';
import { MainFiltersCardProps } from './MainFilterCard';

export const styles = {
  container: css`
    width: 100%;
    padding: 0px 41px 0px 0px;
    height: 303px;
    margin-bottom: 54px;
    margin-top: 43px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      background: #ededf6;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #ededf6;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #2e4063;
    }
  `,
  group_container: css`
    display: flex;
    width: 100%;

    :not(:first-child) {
      margin-top: 48px;
    }
  `,
  group_letter: css`
    color: ${ProjectPalette.text.primary};
    font-size: 12px;
    font-weight: 700;
    padding-top: 7px;
    margin-right: 46px;
    height: fit-content;
    width: fit-content;
    line-height: 1;
  `,
  options_container: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
};

export const AlphabeticallyGroupedOptions = (props: MainFiltersCardProps) => {
  const groupedOptions: any = formatCountryOptions(props.shownOptions);

  return (
    <Grid container item spacing={0}>
      <div css={styles.container}>
        {Object.keys(groupedOptions).map((group: any) => {
          return (
            groupedOptions[group].length > 0 && (
              <div css={styles.group_container} key={group}>
                <div css={styles.group_letter}>{group}</div>
                <Grid container spacing={0}>
                  {groupedOptions[group].map((option: any) => (
                    <FilterOptionItem
                      {...props}
                      key={`${option.code}-${option.name}`}
                      value={option.code}
                      label={option.name}
                      selected={
                        findIndex(
                          props.selectedOptions,
                          (i: string) => i === option.code
                        ) > -1
                      }
                    />
                  ))}
                </Grid>
              </div>
            )
          );
        })}
      </div>
    </Grid>
  );
};
