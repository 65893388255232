import { atom, RecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const drawerAtom = atom({
  key: 'drawerAtom', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const planUserCountAtom = atom({
  key: 'planUserCountAtom', // unique ID (with respect to other atoms/selectors)
  default: 1, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const planNameAtom: RecoilState<'Free' | 'Standard' | 'Premium'> = atom({
  key: 'planNameAtom', // unique ID (with respect to other atoms/selectors)
  default: 'Free', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const planSupportAtom = atom({
  key: 'planSupportAtom', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const freeTrialAtom = atom({
  key: 'freeTrialAtom', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const installmentPaymentIdAtom = atom({
  key: 'installmentPaymentIdAtom', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const testPaymentIdAtom = atom({
  key: 'testPaymentIdAtom', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const filterPopupAtom = atom({
  key: 'filterPopupAtom', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

export interface SelectedFilterAtomModel {
  countries: string[];
  regions: string[];
  budget: string[];
  sectors: string[];
  donors: string[];
  organisations: string[];
  publishers: string[];
  period: string[];
  activitystatus: string[];
  activityscope: string[];
  documentlinkcategory: string[];
  hierarchy: string[];
  humanitarianscopevocab: string[];
  humanitarian: string[];
  humanitarianscope: string[];
  iativersion: string[];
  otheridentifiertype: string[];
  tag: string[];
  defaultaidtype: string[];
  defaultaidtypecategory: string[];
  defaultaidtypevocabulary: string[];
  currency: string[];
  defaultfinancetype: string[];
  defaultflowtype: string[];
  language: string[];
  defaulttiedstatus: string[];
  transactionreceiverorg: string[];
  reportingorgtype: string[];
  collaborationtype: string[];
  secondaryreporter: string[];
  sectorvocabulary: string[];
  policymarker: string[];
  transactionflowtype: string[];
  transactiontiedstatus: string[];
  transactionhumanitarian: string[];
  transactiontype: string[];
  transactionvaluecurrency: string[];
  transactionvalue: string[];
}

export const selectedFilterAtom: RecoilState<SelectedFilterAtomModel> = atom({
  key: 'selectedFilterAtom',
  default: {
    countries: [] as string[],
    regions: [] as string[],
    budget: [] as string[],
    sectors: [] as string[],
    donors: [] as string[],
    organisations: [] as string[],
    publishers: [] as string[],
    period: [] as string[],
    activitystatus: [] as string[],
    activityscope: [] as string[],
    documentlinkcategory: [] as string[],
    hierarchy: [] as string[],
    humanitarianscopevocab: [] as string[],
    humanitarian: [] as string[],
    humanitarianscope: [] as string[],
    iativersion: [] as string[],
    otheridentifiertype: [] as string[],
    tag: [] as string[],
    defaultaidtype: [] as string[],
    defaultaidtypecategory: [] as string[],
    defaultaidtypevocabulary: [] as string[],
    currency: [] as string[],
    defaultfinancetype: [] as string[],
    defaultflowtype: [] as string[],
    language: [] as string[],
    defaulttiedstatus: [] as string[],
    transactionreceiverorg: [] as string[],
    reportingorgtype: [] as string[],
    collaborationtype: [] as string[],
    secondaryreporter: [] as string[],
    sectorvocabulary: [] as string[],
    policymarker: [] as string[],
    transactionflowtype: [] as string[],
    transactiontiedstatus: [] as string[],
    transactionhumanitarian: [] as string[],
    transactiontype: [] as string[],
    transactionvaluecurrency: [] as string[],
    transactionvalue: [] as string[],
  },
});

export const filterCardTabState = atom({
  key: 'filterCardTabState',
  default: [
    {
      index: 0,
      active: false,
      disabled: true,
      label: 'Recent',
      handleClick: () => {
        // do nothing
      },
    },
    {
      index: 1,
      active: true,
      disabled: false,
      label: 'All',
      handleClick: () => {
        // do nothing
      },
    },
  ],
  dangerouslyAllowMutability: true,
});

export const uploadedFilesAtom = atom({
  key: 'uploadedFilesAtom',
  default: [] as File[],
});

export const financialRouteTabsAtom = atom({
  key: 'financialRouteTabsAtom',
  default: 'Summary',
});

export const lineYearPeriodFilter = atom({
  key: 'lineYearPeriodFilter',
  default: '',
});

export const exploreLinksState = atom({
  key: 'exploreLinksState',
  default: 0,
});

export const detailLinksState = atom({
  key: 'detailLinksState',
  default: 0,
});

export const SwitchMapState = atom({
  key: 'switchMapState', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

export const SwitchExploreMapTableState = atom({
  key: 'switchExploreMapTableState', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

export const GenericDialogState = atom({
  key: 'genericDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const DeleteAccountDialogState = atom({
  key: 'deleteAccountDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const ChangeEmailDialogState = atom({
  key: 'changeEmailDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const ChangePhotoDialogState = atom({
  key: 'changePhotoDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const LandingDialogState = atom({
  key: 'changeLandingDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const ExploreTooltipState = atom({
  key: 'exploreTooltipState', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

export const editFileAtom = atom({
  key: 'editFileAtom',
  default: {
    fileName: '',
    type: '',
    published: false,
  },
});

export const ChangeUsernameDialogState = atom({
  key: 'changeUsernameDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const ChangeBirthdayDialogState = atom({
  key: 'changeBirthdayDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const ChangePasswordDialogState = atom({
  key: 'changePasswordDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const ChangePhonenumberDialogState = atom({
  key: 'changePhonenumberDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const PasswordSecurityDialogState = atom({
  key: 'passwordSecurityDialogState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const ContinueExploringDialogState = atom({
  key: 'continueExploringDialogState', // unique ID (with respect to other atoms/selectors)
  default: {
    open: false,
    redirect: '/',
  }, // default value (aka initial value)
});
