import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { DatePicker } from 'app/components/DatePicker';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { FilterProps } from '../index';
import { MainFiltersCard } from './common/MainFilterCard';
import { useRecoilState } from 'recoil';
import { filterPopupAtom, selectedFilterAtom } from 'app/state/recoil/atoms';

export const PeriodFilter = (props: FilterProps) => {
  const [range, setRange] = React.useState<
    {
      startDate: null | Date;
      endDate: null | Date;
    }[]
  >([
    {
      startDate: null,
      endDate: null,
    },
  ]);

  const [currentOpenFilter, setCurrentOpenFilter] =
    useRecoilState(filterPopupAtom);
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFilterAtom);

  React.useEffect(() => {
    if (props.selectedFilters.period.length > 0) {
      setRange([
        {
          endDate: new Date(props.selectedFilters.period[0].endDate),
          startDate: new Date(props.selectedFilters.period[0].startDate),
        },
      ]);
    }
  }, []);

  React.useEffect(() => {
    if (range[0].startDate != null && range[0].endDate != null) {
      range[0].startDate.setHours(12);
      range[0].endDate.setHours(12);
      props.setSelectedOptions([
        {
          startDate: `${range[0].startDate.getFullYear()}/${
            range[0].startDate.getUTCMonth() + 1
          }/${range[0].startDate.getUTCDate()}`,
          endDate: `${range[0].endDate.getFullYear()}/${
            range[0].endDate.getUTCMonth() + 1
          }/${range[0].endDate.getUTCDate()}`,
        },
      ]);
    }
  }, [range]);

  function handleDateChange(
    newDate: Date,
    fromActive: boolean,
    toActive: boolean
  ) {
    setRange((prevState) => {
      const startDate = fromActive ? newDate : prevState[0].startDate;
      const endDate = toActive ? newDate : prevState[0].endDate;
      return [{ startDate, endDate }];
    });
  }

  function handleResetClick() {
    setRange([{ startDate: null, endDate: null }]);

    setSelectedFilters({
      ...selectedFilters,
      [currentOpenFilter]: [],
    });
    setCurrentOpenFilter('');
  }

  function shouldDisableApply() {
    return (
      range[0].startDate === null ||
      range[0].endDate === null ||
      range[0].endDate < range[0].startDate
    );
  }

  return (
    <MainFiltersCard
      title="Period Filter"
      {...props}
      disableApply={shouldDisableApply()}
      removeTopControls
    >
      <Grid container item xs={12} justifyContent="center">
        <DatePicker
          dateRange={range}
          handleDateChange={handleDateChange}
          handleResetClick={handleResetClick}
        />
      </Grid>
    </MainFiltersCard>
  );
};
