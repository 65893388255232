import { css } from 'styled-components/macro';

export const appbarcss = css`
  top: 0;
  z-index: 10;
  width: 100%;
  height: 78px;
  position: fixed;
  transition: background 0.2s ease-in-out;
`;

export const appbartoolbarcss = css`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const logolinkcss = css`
  display: flex;
  align-items: center;
  width: fit-content;

  > div {
    color: #2e4063;
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    font-style: normal;
    letter-spacing: 0.02em;
  }
`;

export const linksContainerCss = css`
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  grid-template-columns: repeat(5, 7rem);

  button {
    height: 30px;
    width: 112px;
    border-radius: 16px;
    padding: 12px, 24px, 12px, 24px;
    background: #2e4063;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: center;
    border: none;
    cursor: pointer;
    outline: none;
    transition: 0.3s all;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const appbarlinkcss = (active: boolean) => css`
  position: relative;
  font-weight: ${active ? 700 : 400};
  &:hover {
    font-weight: 700;
  }
`;
