/// <reference types="styled-components/cssprop" />

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'app';
import 'index.css';
import '../node_modules/react-vis/dist/style.css';

// this import looks unused; it isn't so please do not remove
// eslint-disable-next-line @typescript-eslint/no-unused-vars,import/no-unresolved

import reportWebVitals from 'reportWebVitals';

import { unregister } from './serviceWorker';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === 'production') {
//   serviceWorker.register();
// } else {
//   serviceWorker.unregister();
// }
unregister();
reportWebVitals();
