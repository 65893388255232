import { FilterItemProps } from './common/FilterItem';

export const filterData: FilterItemProps[] = [
  { label: 'locations' },
  // { label: 'countries' },
  // { label: 'regions' },
  { label: 'budget', disabled: true },
  { label: 'sectors' },
  // { label: 'donors' },
  { label: 'organisations' },
  // { label: 'publishers' },
  { label: 'period', disabled: true },
  { label: 'activitystatus' },
  // { label: 'USD', disabled: true },
];

export interface FilterProps {
  openSearch?: boolean;
  forceHide?: boolean;
}

export function getFilterGroups(
  pathname: string,
  mainOpen: boolean
): FilterItemProps[] {
  let filterGroups: FilterItemProps[];

  if (mainOpen) {
    filterGroups = [
      { label: 'locations', disabled: false },
      // { label: 'countries', disabled: false },
      // { label: 'regions', disabled: false },
      { label: 'outgoing commitment', disabled: false },
      { label: 'sectors', disabled: false },
      // { label: 'donors', disabled: false },
      { label: 'organisations', disabled: false },
      // { label: 'publishers', disabled: false },
      { label: 'period', disabled: false },
      { label: 'activitystatus', disabled: false },
      // { label: 'USD', disabled: true },
    ];
  } else {
    filterGroups = [
      { label: 'activity', disabled: false },
      { label: 'default', disabled: false },
      { label: 'organisation', disabled: false },
      { label: 'sector', disabled: false },
      { label: 'transaction', disabled: false },
    ];
  }

  if (mainOpen) {
    if (pathname.indexOf('country/') > -1) {
      filterGroups[0].disabled = true;
    }
    if (pathname.indexOf('donor/') > -1) {
      filterGroups[3].disabled = true;
    }
    if (pathname.indexOf('organisation/') > -1) {
      filterGroups[3].disabled = true;
    }
    if (pathname.indexOf('publisher/') > -1) {
      filterGroups[3].disabled = true;
    }
  }
  return filterGroups;
}
