// @ts-nocheck
import React from 'react';
import { useRecoilState } from 'recoil';
import { css } from 'styled-components/macro';
import {
  SwitchMapState,
  SwitchExploreMapTableState,
} from 'app/state/recoil/atoms';

export const SwitchButtonContainerStyle = css`
  display: flex;
  min-width: 132px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 32px;
  background: #f7f7f7;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 1.25397px;
  overflow: hidden;
`;

const ActiveStateStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: #2e4063;
  border-radius: 16px;
`;

const InActiveStateStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
`;

const ActiveStateTextStyle = css`
  font-weight: 600;
  color: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
`;

const InActiveStateTextStyle = css`
  font-weight: 600;
  color: #2e4063;
  padding-left: 16px;
  padding-right: 16px;
`;

interface SwitchButtonItemProps {
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export const SwitchButtonItem = (props: SwitchButtonItemProps) => {
  return (
    <div
      onClick={props.onClick}
      css={`
        user-select: none;
        cursor: pointer;
        transition: opacity 150ms;
        &:hover {
          opacity: 0.4;
        }
      `}
    >
      <div css={props.active ? ActiveStateStyle : InActiveStateStyle}>
        <span
          css={props.active ? ActiveStateTextStyle : InActiveStateTextStyle}
        >
          {props.label}
        </span>
      </div>
    </div>
  );
};

export interface SwitchButtonProps {
  itemOne: SwitchButtonItemProps;
  itemTwo: SwitchButtonItemProps;
  explorePage?: boolean;
}

/* todo: it's bit of a quick hack, needs to be refactored in the future */
export const SwitchButton = (props: SwitchButtonProps) => {
  const [genericSwitch, setGenericSwitch] = useRecoilState(SwitchMapState);
  const [exploreMapTable, setExploreMapTable] = useRecoilState(
    SwitchExploreMapTableState
  );

  const active = props.explorePage ? exploreMapTable : genericSwitch;
  const toggle = props.explorePage ? setExploreMapTable : setGenericSwitch;

  return (
    <div css={SwitchButtonContainerStyle}>
      <SwitchButtonItem
        aria-label={props.itemOne.label}
        onClick={() => toggle(true)}
        label={props.itemOne.label}
        active={active}
      />
      <SwitchButtonItem
        aria-label={props.itemOne.label}
        onClick={() => toggle(false)}
        label={props.itemTwo.label}
        active={!active}
      />
    </div>
  );
};
