import { css } from 'styled-components/macro';

export const FileUploadPopoverStyle = {
  root: css`
    .MuiDialog-paperWidthSm {
      min-height: 350px;
      box-shadow: none;
      max-width: 500px;
    }
  `,
  content: css`
    color: #2e4063;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  `,
  title: css`
    .MuiTypography-h6 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }
    margin-top: 10px;
  `,
  buttonSave: css`
    margin-right: 16px;
    margin-bottom: 16px;
    margin-left: 0px !important;
    color: white;
    text-transform: none;
    min-width: 110px;
    background-color: #2e4063;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 20px;
    padding: 6px 24px;
    &:hover {
      color: rgba(46, 64, 99, 1);
    }
  `,
  buttonCancel: css`
    margin-bottom: 16px;
    margin-right: 16px;
    color: #2e4063;
    text-transform: none;
    min-width: 110px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 20px;
    padding: 6px 24px;
  `,
  switchButtonDiv: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
};

export const FileUploaderrStyle = {
  dropzone: css`
    background: #ededf6;
    border-radius: 23px;
    height: 200px;
    width: 448px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  typo: css`
    color: #2e4063;
    text-align: center;
    font-size: 18px;
    width: 253px;
  `,
  file: css`
    color: #2e4063;
    align-self: flex-start;
    padding: 10px 10px 10px 0px;
  `,
  fileName: css`
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  `,
  fileLine: css`
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    margin-bottom: 15px;
  `,
};
