import React from 'react';

export interface SearchComponentProps {
  close: Function;
  stayOpen?: boolean;
}

export interface SearchComponentLayoutProps {
  width: number;
  value: string;
  close: Function;
  loading: boolean;
  stayOpen?: boolean;
  setValue: Function;
  resultType: string;
  loadMore: Function;
  hasMoreOfType: boolean;
  setResultType: Function;
  onEnterPress?: Function;
  searchData: NavResultsModel;
  targetRef: React.RefObject<HTMLDivElement>;
  onClickAway: (event: React.MouseEvent<Document>) => void;
}

export interface ResultModel {
  name: string;
  link: string;
}

export interface NavResultItemModel {
  count: number;
  data: ResultModel[];
}

export interface NavResultsModel {
  Activities: NavResultItemModel;
  Countries: NavResultItemModel;
  Organisations: NavResultItemModel;
  Publishers: NavResultItemModel;
  Donors: NavResultItemModel;
  All: NavResultItemModel;
}

export const datapath = 'data.data';
export const countpath = 'data.count';

export const searchNavItems = [
  'Activities',
  'Countries',
  'Organisations',
  'Publishers',
  'Donors',
  'All',
];

const mockResultItem = {
  name: 'Activity',
  link: '',
};

export const initData: NavResultsModel = {
  Activities: {
    count: 0,
    data: [],
  },
  Countries: {
    count: 0,
    data: [],
  },
  Organisations: {
    count: 0,
    data: [],
  },
  Publishers: {
    count: 0,
    data: [],
  },
  Donors: {
    count: 0,
    data: [],
  },
  All: {
    count: 0,
    data: [],
  },
};

export const mockResultsData = {
  Activities: [
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
    mockResultItem,
  ],
  Countries: [],
  Organisations: [],
  Publishers: [],
  Donors: [],
  All: [],
};
