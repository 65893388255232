import React from 'react';
import Providers from 'app/Providers';
import { ModuleRoutes } from 'app/Routes';
import { Drawer } from 'app/components/Drawer';
import { AppDialogs } from 'app/components/Dialogs';
import { CookieDialog } from 'app/components/feedback/CookieDialog';

export function App() {
  return (
    <Providers>
      <Drawer />
      <ModuleRoutes />
      <AppDialogs />
      <CookieDialog
        data-testid="cookie-dialog"
        message="The website uses cookies for tracking statistics. Read Grand Bargains data privacy for more details."
        open
      />
    </Providers>
  );
}
