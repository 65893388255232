import React from 'react';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import { useRecoilState } from 'recoil';
import { useUpdateEffect } from 'react-use';
import MUIList from '@material-ui/core/List';
import { useAuth0 } from '@auth0/auth0-react';
import { userMock } from 'app/interfaces/User';
import { useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import MUIDrawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { drawerAtom } from 'app/state/recoil/atoms';
import IconButton from '@material-ui/core/IconButton';
import { DrawerItem } from 'app/components/Drawer/common/DrawerItem';
import { SignInCard } from 'app/components/Drawer/common/SignInCard';
import { ProfileCard } from 'app/components/Drawer/common/ProfileCard';
import { useStoreState, useStoreActions } from 'app/state/store/hooks';
import { GlobalNavItemProps, GlobalNavItems } from 'app/components/Drawer/data';

export const Drawer = () => {
  const location = useLocation();
  const { user, isAuthenticated, logout } = useAuth0();

  const [drawerState, setDrawerState] = useRecoilState(drawerAtom);

  const userDB = useStoreState((state) => state.user.data);
  const clearUserAction = useStoreActions((actions) => actions.user.clear);

  useUpdateEffect(() => {
    if (drawerState) {
      setDrawerState(false);
    }
  }, [location.pathname]);

  useUpdateEffect(() => {
    if (get(location, 'state.openMenu', false) && !drawerState) {
      setDrawerState(true);
    }
  }, [location.state]);

  return (
    <MUIDrawer
      anchor="right"
      open={drawerState}
      onClose={() => setDrawerState(false)}
    >
      <Box width="310px">
        <div
          role="presentation"
          css={`
            padding: 0 16px;
          `}
        >
          <div
            css={`
              width: 100%;
              display: flex;
              justify-content: flex-end;
              transform: translateX(10px);
            `}
          >
            <IconButton
              aria-label="close"
              onClick={() => setDrawerState(false)}
              css={`
                color: #2e4063;
              `}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Box width="100%" height="30px" />
          <MUIList disablePadding>
            {GlobalNavItems.map((item: GlobalNavItemProps, index: number) => (
              <DrawerItem {...item} key={index} />
            ))}
          </MUIList>
          <Divider
            css={`
              margin: 40px 0;
              background: #a1aebd;
            `}
          />
          {isAuthenticated ? (
            <ProfileCard
              logout={logout}
              user={user || userMock}
              clearUserAction={clearUserAction}
              userFromStore={get(userDB, 'user', null)}
              orgName={get(userDB, 'groups[0].name', null)}
            />
          ) : (
            <SignInCard />
          )}
        </div>
      </Box>
    </MUIDrawer>
  );
};
