import React, { ChangeEvent } from 'react';
import { Slider, styles } from 'app/components/Slider/styles';
import { Tooltip } from 'app/components/Slider/styles';

interface RangeSliderProps {
  handleChange: (event: ChangeEvent<{}>, value: number | number[]) => void;
  value: number[];
}
export default function RangeSlider(props: RangeSliderProps) {
  return (
    <div css={styles.container}>
      <span css={styles.label}>Min</span>
      <Slider
        value={props.value}
        onChange={props.handleChange}
        valueLabelDisplay="on"
        ValueLabelComponent={ValueLabelComponent}
        max={1600}
      />
      <span css={styles.label}>Max</span>
    </div>
  );
}

interface ValueLabelComponentProps {
  children: React.ReactElement;
  value: number;
}

function ValueLabelComponent(props: ValueLabelComponentProps) {
  const { children, value } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setOpen(true), 200);
  }, []);

  return (
    <Tooltip open={open} placement="top" title={`US$ ${value}M`} arrow>
      {children}
    </Tooltip>
  );
}
