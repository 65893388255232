import React from 'react';
import { css } from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import findIndex from 'lodash/findIndex';
import { ProjectPalette } from 'app/theme';
import { FilterProps } from '../index';
import { MainFiltersCard, MainFiltersCardProps } from './common/MainFilterCard';
import { formatRegionOptions } from './common/utils';
import { FilterOptionItem } from './common/FilterOptionItem';

export const RegionsFilterContainerStyle = css`
  width: 100%;
  height: 303px;
  margin-top: 43px;
  overflow-y: scroll;
  margin-bottom: 54px;

  &::-webkit-scrollbar {
    width: 6px;
    background: #ededf6;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #ededf6;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #2e4063;
  }
`;

export const RegionsFilter = (props: FilterProps) => (
  <MainFiltersCard title="Regions" {...props}>
    <FilterOptions {...props} key="RegionsFilter-container" />
  </MainFiltersCard>
);

export const FilterOptions = (props: MainFiltersCardProps) => {
  const groupedOptions: any = formatRegionOptions(props.shownOptions);

  function selectAllFromGroup(group: any) {
    const updatedSelectedOptions = [...props.selectedOptions];
    for (let i = 0; i < groupedOptions[group].length; i++) {
      if (!updatedSelectedOptions.includes(groupedOptions[group][i].code)) {
        updatedSelectedOptions.push(groupedOptions[group][i].code);
      }
    }
    props.setSelectedOptions(updatedSelectedOptions);
  }

  return (
    <Grid container item spacing={0}>
      <div css={RegionsFilterContainerStyle}>
        {Object.keys(groupedOptions).map((group: string) => {
          return (
            groupedOptions[group].length > 0 && (
              <div
                key={group}
                css={`
                  display: flex;
                  width: 100%;

                  :not(:first-child) {
                    margin-top: 25px;
                  }
                `}
              >
                <div
                  key={group}
                  css={`
                    color: ${ProjectPalette.text.primary};
                    font-size: 12px;
                    font-weight: 700;
                    margin-right: 37px;
                    padding-top: 6px;
                    height: fit-content;
                    width: 70px !important;
                    line-height: 1;
                    :hover {
                      cursor: pointer;
                    }
                  `}
                  onClick={() => {
                    selectAllFromGroup(group);
                  }}
                >
                  {group}
                </div>
                <Grid container item spacing={0}>
                  {groupedOptions[group].map((option: any) => (
                    <FilterOptionItem
                      {...props}
                      key={`${option.code}-${option.name}`}
                      value={option.code}
                      label={option.name}
                      selected={
                        findIndex(
                          props.selectedOptions,
                          (i: string) => i === option.code
                        ) > -1
                      }
                    />
                  ))}
                </Grid>
              </div>
            )
          );
        })}
      </div>
    </Grid>
  );
};
