import React from 'react';
import { css } from 'styled-components/macro';
import { ProjectPalette } from 'app/theme';
import { useRecoilState } from 'recoil';
import { filterCardTabState } from 'app/state/recoil/atoms';

interface TabProps {
  active: boolean;
  disabled: boolean;
  label: string;
  index: number;
  handleClick: Function;
}

const tabStyle = (active: boolean, disabled?: boolean) => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${!disabled ? 'bold' : '400'};
  font-size: 12px;
  line-height: 18px;
  color: ${!disabled ? '#2e4063' : 'rgba(61, 61, 61, 0.6)'};
  font-size: 12px;
  margin-right: 30px;
  padding-left: 2px;
  padding-bottom: ${active ? '2px' : '4px'};
  border-bottom: ${active
    ? `2px solid ${ProjectPalette.primary.main}`
    : 'initial'};

  :hover {
    cursor: ${!active && !disabled ? 'pointer' : 'initial'};
    // color: ${!active && !disabled ? ProjectPalette.primary.main : 'initial'};
  }
`;

export interface TabsProps {
  sector?: boolean;
  locations?: boolean;
  organisations?: boolean;
}

const standardTabs: TabProps[] = [
  {
    index: 0,
    active: false,
    disabled: true,
    label: 'Recent',
    handleClick: () => {},
  },
  {
    index: 1,
    active: true,
    disabled: false,
    label: 'All',
    handleClick: () => {},
  },
];

const sectorTabs: TabProps[] = [
  {
    index: 0,
    active: false,
    disabled: true,
    label: 'Recent',
    handleClick: () => {},
  },
  {
    index: 1,
    active: true,
    disabled: false,
    label: 'Sector (DAC3)',
    handleClick: () => {},
  },
  {
    index: 2,
    active: false,
    disabled: false,
    label: 'Sector (DAC5)',
    handleClick: () => {},
  },
];

const locationTabs: TabProps[] = [
  {
    index: 0,
    active: false,
    disabled: true,
    label: 'Recent',
    handleClick: () => {},
  },
  {
    index: 1,
    active: true,
    disabled: false,
    label: 'Countries',
    handleClick: () => {},
  },
  {
    index: 2,
    active: false,
    disabled: false,
    label: 'Regions',
    handleClick: () => {},
  },
];

const organisationTabs: TabProps[] = [
  {
    index: 0,
    active: false,
    disabled: true,
    label: 'Recent',
    handleClick: () => {},
  },
  {
    index: 1,
    active: false,
    disabled: false,
    label: 'Donors',
    handleClick: () => {},
  },
  {
    index: 2,
    active: false,
    disabled: false,
    label: 'Organisations',
    handleClick: () => {},
  },
  {
    index: 3,
    active: true,
    disabled: false,
    label: 'Publishers',
    handleClick: () => {},
  },
];

const Tab = (props: TabProps): JSX.Element => {
  return (
    <span
      onClick={() => props.handleClick(props.index)}
      css={tabStyle(props.active, props.disabled)}
    >
      {props.label}
    </span>
  );
};

export const Tabs = (props: TabsProps) => {
  const [tabs, setTabs] = useRecoilState(filterCardTabState);

  React.useEffect(() => {
    if (props.sector) {
      // @ts-ignore
      setTabs(sectorTabs);
    }
    if (props.locations) {
      // @ts-ignore
      setTabs(locationTabs);
    }
    if (props.organisations) {
      // @ts-ignore
      setTabs(organisationTabs);
    }

    return function onUnMount() {
      // @ts-ignore
      setTabs(standardTabs);
    };
  }, []);

  function handleTabClick(index: number) {
    setTabs((state) => {
      if (!state[index].disabled) {
        state.map((tab) => (tab.active = false));
        state[index].active = true;
      }
      return [...state];
    });
  }

  return (
    <>
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={tab.index}
            index={index}
            disabled={tab.disabled}
            active={tab.active}
            label={tab.label}
            handleClick={handleTabClick}
          />
        );
      })}
    </>
  );
};
